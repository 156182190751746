import { globalHistory } from '@reach/router'

export const onRouteUpdate = () => {
  globalHistory.listen(args => {
    args.location.action = args.action
  })
}
// import { globalHistory } from '@reach/router'

// // Esta función se ejecutará cuando se complete el renderizado inicial del cliente
// export const onInitialClientRender = () => {
//   (window.adsbygoogle = window.adsbygoogle || []).push({});
// }

// // Esta función se ejecutará cada vez que se actualice una ruta
// export const onRouteUpdate = () => {
//   globalHistory.listen(args => {
//     args.location.action = args.action
//   })
// }
